export default () => ({
    selectedCategory: {},
    isOpen: false,
    filterText: 'Filter By:',
    init() {
        this.$watch('selectedCategory', (value) => {
            const mediaComponent = document.querySelector('#media-component');
            if (mediaComponent) {
                htmx.trigger(mediaComponent, 'refresh', { 'selectedCategory': value.slug });
            }
        });
    },
    isCategoryEmpty() {
        return Object.keys(this.selectedCategory).length === 0;
    },
    isCategoryNotEmpty() {
        return Object.keys(this.selectedCategory).length > 0;
    },
    closeDropdown() {
        this.isOpen = false;
    },
    clearCategory() {
        this.selectedCategory = {};
    },
    getDisplayText() {
        return this.selectedCategory.title || 'Filter By:';
    },
    handleClick() {
        this.isOpen = !this.isOpen;
    },
    selectCategory() {
        const title = event.target.dataset.title;
        const slug = event.target.dataset.slug;
        this.selectedCategory = { title, slug };
        this.isOpen = false;
    }
})
