import $ from 'jquery';
import Alpine from '@alpinejs/csp';
import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import mediaFilter from './components/mediaFilter';

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

Alpine.data('mediaFilter', mediaFilter);

Alpine.start();

/**
 * Require jQuery
 */
window.$ = $;
window.jQuery = $;
/**
 * Require other packages
 */
import magnificPopup from 'magnific-popup';
import Parallax from 'parallax-js';
import imagesLoaded from 'imagesloaded';

gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin);

$(function() {
  if ($('.other a').length > 0) {
    $(".other a").magnificPopup({
      type: "inline",
      preloader: false,
      modal: true,
    });
  }
});

$(function() {
  if ($('.video-link').length > 0) {
    $(".video-link").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      disableOn: 0,
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: function(url) {
              let params = url.split('v=');
              if (params[1].indexOf('&t') > 0) {
                return params[1].split('&t=')[0] + "?start=" + params[1].split('&t=')[1].split('s')[0] + "&autoplay=1";
              } else {
                return params[1] + "?autoplay=1";
              }
            },
            src: '//www.youtube.com/embed/%id%',
          }
        }
      },
    });
  }
});

function ready(fn) {
  if (document.readyState != "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}
ready(go);

const resetSlides = (greenRing, others, index) => new Promise(
  (resolve) => {
    greenRing.classList.remove("active-1");
    greenRing.classList.remove("active-2");
    greenRing.classList.remove("active-3");
    greenRing.classList.remove("active-4");
    greenRing.classList.remove("active-5");
    greenRing.classList.add("active-" + (index + 1));
    others.forEach(i => {
      gsap.fromTo(
        "#slide" + (i + 1) + "-path", {
          duration: 1.5,
          stroke: "#82CA9C",
          drawSVG: "49% 51%",
          ease: "expo.out",
        }, {
          duration: 2,
          stroke: "#82CA9C",
          drawSVG: "45% 55%",
          ease: "expo.out",
          onComplete: () => {
            // console.log(i == others.length);
            // console.log(
            //   document.querySelector("#slide" + (index + 1) + "-path").style.stroke
            // );
            if (
              i == others.length &&
              document.querySelector("#slide" + (index + 1) + "-path").style.stroke != 'rgb(36, 147, 85)'
            ) {
              // console.log('fix');
            }
          }
        }
      );
    });
    resolve('something');
    return;
  });

function animate(greenRing, others, current) {
  resetSlides(greenRing, others, current).then(() => {
    // gsap.to(
    //   "#slide" + (current + 1) + "-path", {
    //     callbackScope: 'onStart',
    //     duration: 1,
    //     stroke: "#249355",
    //     drawSVG: "0% 100%",
    //     ease: "bounce",
    //     onComplete: () => {
    //       window.animating = false
    //     }
    //   });
    gsap.fromTo(
      "#slide" + (current + 1) + "-path", {
        duration: 0,
        stroke: "#249355",
        drawSVG: "45% 55%",
        ease: "none",
      }, {
        callbackScope: 'onStart',
        duration: 1.5,
        stroke: "#249355",
        drawSVG: "0% 100%",
        ease: "bounce.out",
        onComplete: () => {
          window.animating = false
        }
      });
  });
}

function collapseBox(el, button) {
  el.classList.remove("expanded");
  button.setAttribute("aria-expanded", "false");
  let textboxText = el.querySelector('.textbox-text');
  textboxText.style.maxHeight = 0 + "px";
  textboxText.classList.remove('expanded');
}

function expandBox(el, button, x) {
  el.classList.add("expanded");
  button.setAttribute("aria-expanded", "true");
  let textboxText = el.querySelector('.textbox-text');
  let padding = x < 1270 ? 20 : 30;
  textboxText.style.maxHeight = textboxText.getAttribute('data-height') * 1 + padding * 1 + "px";
  textboxText.classList.add('expanded');
}

function resetBoxes(boxes, x) {
  [...boxes].map(
    box => {
      let boxText = box.querySelector('.textbox-text');
      boxText.setAttribute(
        "data-height",
        boxText.scrollHeight
      );
      if (x < 768) {
        boxText.style.maxHeight =
          boxText.getAttribute("data-height") + "px";
      } else {
        let button = box.querySelector('.indicator');
        collapseBox(box, button);
      }
    }
  );
}

function go() {
  // global vars for window width, body element, scroll effects, etc
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  /*
   * Attune boxes
   */
  if (
    typeof document.getElementById("attune-diagram") !== "undefined" &&
    document.getElementById('attune-diagram') !== null
  ) {
    var boxes = document.querySelectorAll(".diagram-textbox");
    resetBoxes(boxes, x);
    [...boxes].map(
      box => {
        let button = box.querySelector('.indicator');
        box.addEventListener('click', function(e) {
          if (box.classList.contains("expanded")) {
            collapseBox(box, button);
            return;
          } else {
            expandBox(box, button, x);
            return;
          }
        });
      }
    );
  }
  /*
   ** Vision & Values interactive element =====================================
   */
  if (
    typeof document.getElementById("vision-values-nav") !== "undefined" &&
    document.getElementById("vision-values-nav") !== null
  ) {
    // console.log("vision-values");
    var buttons = document.querySelectorAll("#vision-values-nav button");
    var slidesText = document.querySelectorAll(
      ".slides > div:nth-of-type(2) > div"
    );
    var photos = document.querySelectorAll(
      ".slides > div:nth-of-type(1) .images > div.image-wrapper"
    );
    var greenRing = document.getElementById("ring-green");
    var animDuration = 2;
    gsap.to("#slide2-path", {
      duration: animDuration,
      stroke: "#82CA9C",
      drawSVG: "45% 55% live",
      ease: "bounce.out",
    });
    gsap.to("#slide3-path", {
      duration: animDuration,
      stroke: "#82CA9C",
      drawSVG: "45% 55% live",
      ease: "bounce.out",
    });
    gsap.to("#slide4-path", {
      duration: animDuration,
      stroke: "#82CA9C",
      drawSVG: "45% 55% live",
      ease: "bounce.out",
    });
    gsap.to("#slide5-path", {
      duration: animDuration,
      stroke: "#82CA9C",
      drawSVG: "45% 55% live",
      ease: "bounce.out",
    });
    window.animating = false;
    Array.prototype.forEach.call(buttons, function(button) {
      button.addEventListener("click", function(e) {
        e.preventDefault;
        var index = Array.prototype.indexOf.call(buttons, button);
        Array.prototype.forEach.call(buttons, function(button) {
          button.parentNode.classList.remove("active");
        });
        button.parentNode.classList.add("active");
        Array.prototype.forEach.call(slidesText, function(slide) {
          slide.classList.remove("active");
        });
        slidesText.item(index).classList.add("active");
        Array.prototype.forEach.call(photos, function(slide) {
          slide.classList.remove("active");
        });
        photos.item(index).classList.add("active");
        let current = index;
        let slides = [0, 1, 2, 3, 4];
        let others = slides.filter(function(slide) {
          return slide !== current
        });
        // if (window.animating) {
        //   setTimeout(
        //     () => {
        //       animate(greenRing, others, current);
        //       window.animating = true;
        //     }, 1600);
        // } else {
        animate(greenRing, others, current);
        // animate2(greenRing, current);
        // window.animating = true;
        // }
      });
    });
  }

  /*
   ** REMOVED Original homepage circles animation was here ====================
   */

  /*
   ** Cookie policy check =====================================================
   */
  if (
    typeof document.getElementById("cookie-policy-accept") !== "undefined" &&
    document.getElementById("cookie-policy-accept") !== null
  ) {
    document
      .getElementById("cookie-policy-accept")
      .addEventListener("click", function(e) {
        e.preventDefault();
        document.cookie =
          "acceptCookiePolicy=true;expires=Fri, 31 Dec 2030 23:59:59 GMT";
        document.getElementById("cookie-policy-modal").style.display = "none";
      });
  }

  // $("select:not(.skip-chosen)").chosen({ disable_search_threshold: 10 });
  /*
   ** Contact form interest dropdown
   */
  if (
    typeof document.getElementById("interest") !== "undefined" &&
    document.getElementById("interest") !== null
  ) {
    interestField = document.getElementById("interest");
    emailField = document.getElementById("toEmail");

    $(interestField).on("change", function(evt, params) {
      // do_something(evt, params);
      switch ($(interestField).val()) {
        case "Licensing & Collaborations":
          // console.log("licensing");
          $(emailField).val(bdEmail);
          break;
        case "Public Relations":
          // console.log("pr");
          $(emailField).val(prEmail);
          break;
        case "Investor Relations":
          // console.log("ir");
          $(emailField).val(irEmail);
          break;
      }
    });
  }

  /*
   ** Sets subject for job application form ===================================
   */
  if (
    typeof document.getElementById("applicant-form") !== "undefined" &&
    document.getElementById("applicant-form") !== null
  ) {
    var appForm = document.getElementById("applicant-form");
    var appName = document.getElementById("from-name");
    var appSubject = document.getElementById("subject");
    appForm.addEventListener("submit", function(e) {
      appSubject.setAttribute(
        "value",
        appName.value + " for " + appSubject.value
      );
    });
  }

  /*
   ** Sets up hamburger menu icon/toggle ======================================
   */
  var menuToggle = document.getElementById("menu-toggle");
  menuToggle.addEventListener("click", function(e) {
    e.preventDefault();
    if (this.classList.contains("open")) {
      document.getElementById("nav-primary").classList.remove("open");
      document.getElementById("masthead").classList.remove("open");
      this.setAttribute("aria-expanded", "false");
      this.classList.remove("open");
      document.body.classList.remove("nav-open");
    } else {
      document.getElementById("nav-primary").classList.add("open");
      document.getElementById("masthead").classList.add("open");
      this.setAttribute("aria-expanded", "true");
      this.classList.add("open");
      document.body.classList.add("nav-open");
    }
  });

  /*
   ** START fade-in on scroll =================================================
   */
  var fadeInBoxes = document.querySelectorAll(".fade-in");
  var listFadeInBoxes = document.querySelectorAll(".list-fade-in");

  var globalId;

  function scrollStuff() {
    var body = g.getBoundingClientRect();
    // console.log(body.top);
    Array.prototype.forEach.call(fadeInBoxes, function(el, i) {
      var rect = el.getBoundingClientRect();
      // console.log('box top ' + rect.top);
      // console.log('scrollTop ' + document.body.scrollTop);
      // console.log('window.offsetHeight ' + y);
      if (rect.top < y - 100)
        if (!el.classList.contains("visible")) el.classList.add("visible");
    });
    Array.prototype.forEach.call(listFadeInBoxes, function(el, i) {
      var rect = el.getBoundingClientRect();
      // console.log('box top ' + rect.top);
      // console.log('scrollTop ' + document.body.scrollTop);
      // console.log('window.offsetHeight ' + y);
      if (rect.top < y - 100)
        if (!el.classList.contains("visible")) el.classList.add("visible");
    });
    if (
      typeof document.querySelector(".video") !== "undefined" &&
      document.querySelector(".video") !== null
    ) {
      var nonHero = document.querySelector(".content-wrapper-inner");
      var nonHeroRect = nonHero.getBoundingClientRect();
      // console.log(nonHeroRect.top);
      if (nonHeroRect.top < 200) g.classList.add("scrolling");
      else g.classList.remove("scrolling");
    }
    if (body.top < 95) g.classList.add("mobile-scrolling");
    else g.classList.remove("mobile-scrolling");
    globalId = requestAnimationFrame(scrollStuff);
  }

  if (
    typeof document.querySelector(".large-image") !== "undefined" &&
    document.querySelector(".large-image") !== null
  ) {
    imagesLoaded(document.querySelector(".large-image"), function(instance) {
      // console.log('images loaded');
      globalId = requestAnimationFrame(scrollStuff);
    });
  } else globalId = requestAnimationFrame(scrollStuff);
  /*
   ** END fade-in on scroll ===================================================
   */

  /*
   ** START window resize functions ===========================================
   */
  var resizeId;
  window.addEventListener("resize", function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(doneResizing, 5);
  });

  function doneResizing() {
    var resizeX = w.innerWidth || e.clientWidth || g.clientWidth;
    var resizeY = w.innerHeight || e.clientHeight || g.clientHeight;
    // console.log(resizeX);
    // scrolloverSetup(resizeX);
    // if (slider) {
    //   slider.reloadSlider();
    // }
    if (
      typeof document.getElementById("attune-diagram") !== "undefined" &&
      document.getElementById('attune-diagram') !== null
    ) {
      var boxes = document.querySelectorAll(".diagram-textbox");
      resetBoxes(boxes, resizeX);
    }
    /*
     ** END window resize functions ===============================================
     */
  }

  /*
   ** form file field enhancements ================================================
   */
  var inputs = document.querySelectorAll(".inputfile");
  Array.prototype.forEach.call(inputs, function(input) {
    var label = input.nextElementSibling,
      labelVal = label.innerHTML;

    input.addEventListener("change", function(e) {
      var fileName = "";
      if (this.files && this.files.length > 1)
        fileName = (this.getAttribute("data-multiple-caption") || "").replace(
          "{count}",
          this.files.length
        );
      else fileName = e.target.value.split("\\").pop();
      if (fileName) label.innerHTML = fileName;
      else label.innerHTML = labelVal;
    });
    input.addEventListener("focus", function() {
      input.classList.add("has-focus");
    });
    input.addEventListener("blur", function() {
      input.classList.remove("has-focus");
    });
  });
}
